<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 v-if="!isNew">
        <v-card style="background-color: white" color="#31708E" title="Manage Contact Groups">
          <v-data-table :loading="loading" :headers="headers" :items="contactGroups">
            <template slot="headerCell" slot-scope="{ header }">
              <span class="font-weight-light text-warning text--darken-3" v-text="header.text" />
            </template>
            <template slot="item" slot-scope="{ item }">
              <tr>
                <td class="text-xs-left">{{ item.groupName }}</td>
                <td class="text-xs-left">{{ item.email }}</td>
                <td class="text-xs-left">{{ item.telephone }}</td>
                <td style="width: 250px">
                  <v-select :items="actions" v-model="item.actions" @change="action(item)" label="Choose Action"></v-select>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="showRemoveContactGroupDialog" max-width="500px" scrollable>
            <v-card :loading="doProccess" :disabled="doProccess">
              <v-card-title class="headline grey lighten-2" primary-title
                >{{ contactGroupAction }} {{ this.selecteditem ? this.selecteditem.groupName : "Contact Group" }}</v-card-title
              >
              <v-card-text>Please confirm contact group {{ lower(contactGroupAction) }} action.</v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" :loading="doProccess" @click="doAction(selecteditem)">{{ upper(contactGroupAction) }}</v-btn>
                <v-btn color="secondary" text @click="cancelAction(contactGroupAction)">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-flex xs12 text-xs-right>
            <v-btn class="mx-2" @click="newAccount()" fab dark color="#5085A5">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-card>
      </v-flex>

      <v-slide-y-transition mode="out-in">
        <v-flex xs12 md12 v-if="isNew">
          <v-card
            style="background-color: white"
            :title="contactGroupAction == 'Reset Password' ? 'Reset Password ' : contactGroupAction + ' Contact Group '"
            text="Complete your contact group information"
            :loading="doProccess"
            :disabled="doProccess"
          >
            <v-form>
              <v-container py-0>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Group Name"
                      v-model="newContactGroup.groupName"
                      v-if="contactGroupAction === 'Edit' || (isNew && contactGroupAction === '')"
                      class="purple-input"
                      :error-messages="groupnameErrors"
                      @blur="$v.newContactGroup.groupName.$touch()"
                      id="groupname"
                    />
                    <v-text-field
                      label="Group Name"
                      v-model="newContactGroup.groupName"
                      v-else
                      :disabled="'disabled'"
                      class="purple-input"
                    />
                  </v-flex>
                  <v-flex xs12 md6>
                    <v-text-field
                      label="Email Address"
                      v-if="contactGroupAction === 'Edit' || (isNew && contactGroupAction === '')"
                      v-model="newContactGroup.email"
                      class="purple-input"
                      :error-messages="emailErrors"
                      @blur="$v.newContactGroup.email.$touch()"
                      id="emailaddress"
                    />
                    <v-text-field label="Email Address" v-else disabled="disabled" v-model="newContactGroup.email" class="purple-input" />
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-text-field
                      label="Telephone"
                      v-if="contactGroupAction === 'Edit' || (isNew && contactGroupAction === '')"
                      v-model="newContactGroup.telephone"
                      class="purple-input"
                      :error-messages="phoneErrors"
                      @blur="$v.newContactGroup.telephone.$touch()"
                      id="telephone"
                    />
                    <v-text-field
                      label="Telephone"
                      :disabled="'disabled'"
                      v-model="newContactGroup.telephone"
                      v-else
                      class="purple-input"
                    />
                  </v-flex>
                  <v-flex xs12 md12>
                    <h3>Contacts:</h3>
                    <v-flex style="height: 200px; overflow-y: auto" xs12 md12>
                      <v-checkbox
                        :key="contact.id"
                        v-for="contact in contacts"
                        v-model="contact.checked"
                        :label="contact.firstName + ' ' + contact.lastName"
                      ></v-checkbox>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 text-xs-right>
                    <v-btn class="mx-0 mr-2 font-weight-light" @click="cancelAction()" color="primary">Cancel</v-btn>
                    <v-btn class="mx-0 font-weight-light" :loading="doProccess" @click="create()" color="#5085A5">{{
                      contactGroupAction == "Edit"
                        ? "Update Contact Group "
                        : contactGroupAction == "Reset Password"
                        ? "Reset Password"
                        : "Create Contact Group "
                    }}</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card>
        </v-flex>
      </v-slide-y-transition>
    </v-layout>
  </v-container>
</template>

<script>
import contactGroupService from "@/services/contactGroupService";
import Vue from "vue";
import contactService from "@/services/contactService";
import { required, minLength, maxLength, email, numeric } from "vuelidate/lib/validators";
import IdGenerator from "../../../shared/extensions/IdGenerator";

export default {
  data() {
    return {
      loading: false,
      contacts: [],
      actions: ["Edit", "Remove"],
      contactGroupAction: "",
      showRemoveContactGroupDialog: false,
      doProccess: false,
      selecteditem: null,
      password: "",
      isNew: false,
      confirmPassword: "",
      newContactGroup: {
        id: null,
        groupName: "",
        contactIds: [],
        email: "",
        telephone: "",
        companyId: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      headers: [
        {
          sortable: false,
          text: "Group Name",
          value: "groupName",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          sortable: false,
          text: "Telephone",
          value: "telephone",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "left",
        },
      ],
      contactGroups: [],
    };
  },
  validations: {
    newContactGroup: {
      groupName: {
        required,
      },
      email: {
        email,
      },
      telephone: {
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
    },
  },
  mounted() {
    this.loadContactGroups();
  },
  methods: {
    newAccount() {
      this.newContactGroup = {
        id: null,
        groupName: "",
        contactIds: [],
        email: "",
        telephone: "",
        companyId: null,
      };
      this.isNew = true;
      this.loadContacts();
    },
    async create() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        this.doProccess = true;
        var data = {
          groupName: this.newContactGroup.groupName,
          email: this.newContactGroup.email,
          telephone: this.newContactGroup.telephone,
          id: this.newContactGroup.id ? this.newContactGroup.id : null,
          companyId: this.newContactGroup.companyId ? this.newContactGroup.companyId : null,
        };

        data.contacts = this.getCheckedContacts(data);
        if (!data.id) {
          Vue.set(data, "companyId", this.companyId);
          const docs = await contactGroupService.checkExist(data);
          if (docs && docs.size > 0) {
            this.$notification.showError("Contact Group with this email already exist");
            return;
          } else {
            data.id = IdGenerator.newId();
            await contactGroupService.updateContactGroupInfo(data);
            this.$notification.showSuccess("The contact group saved successfully.");
            this.loadContactGroups();
            this.isNew = false;
            this.contactGroupAction = "";
          }
        } else {
          const docs = await contactGroupService.checkEmailExist(data);
          if (docs && docs.size > 0) {
            this.$notification.showError("Contact with this email already exist");
            return;
          } else {
            await contactGroupService.updateContactGroupInfo(data);
            this.$notification.showSuccess("The contact group saved successfully.");
            this.loadContactGroups();
            this.isNew = false;
            this.contactGroupAction = "";
          }
        }
      } catch (error) {
        this.$notification.showError(error.message);
      } finally {
        this.doProccess = false;
      }
    },
    getCheckedContacts(data) {
      let selectedItems = this.contacts
        .filter((item) => {
          return item.checked;
        })
        .map((item) => {
          return item.id;
        });
      return selectedItems;
    },
    async loadContactGroups() {
      try {
        this.loading = true;
        const docs = await contactGroupService.getContactGroups(this.companyId);
        this.contactGroups = [];
        docs.forEach((doc) => {
          var u = doc.data();
          u.id = doc.id;
          u.actions = "";
          this.contactGroups.push(u);
        });
        this.$forceUpdate();
      } catch (error) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async loadContacts() {
      try {
        this.contacts = [];
        const docs = await contactService.getContacts(this.companyId);
        this.contacts = [];
        docs.forEach((doc) => {
          var u = doc.data();
          u.id = doc.id;

          if (this.newContactGroup.contacts && this.newContactGroup.contacts.indexOf(doc.id) != -1) {
            u.checked = true;
          }
          this.contacts.push(u);
        });
        this.$forceUpdate();
      } catch (error) {
        this.$notification.showError(error.message);
      }
    },
    action(item) {
      this.selecteditem = item;
      if (item.actions === "Remove") {
        this.contactGroupAction = "Remove";
        this.showRemoveContactGroupDialog = true;
      }
      if (item.actions === "Edit" || item.actions === "Reset Password") {
        this.contactGroupAction = "Edit";
        this.isNew = true;
        this.newContactGroup = item;
        this.loadContacts();
      }
    },
    lower(item) {
      return (item + " ").toLowerCase();
    },
    async doAction(contactGroup) {
      try {
        if (contactGroup.actions == "Remove") {
          this.doProccess = true;
          await contactGroupService.removeContactGroup(contactGroup);
          this.$notification.showSuccess("The contact group removed successfully.");
          this.contactGroupAction = "";
          this.loadContactGroups();
          this.showRemoveContactGroupDialog = false;
        }
      } catch (error) {
        this.$notification.showError(error.message);
      } finally {
        this.doProccess = false;
      }
    },
    cancelAction(contactGroupAction) {
      this.loadContactGroups();
      this.contactGroupAction = "";
      if (this.selecteditem) this.selecteditem.actions = "";

      this.isNew = false;
      this.showRemoveContactGroupDialog = false;
    },
    upper(item) {
      return (item + " ").toUpperCase();
    },
  },
  watch: {
    showRemoveContactGroupDialog: function (val) {
      if (!val) {
        this.selecteditem.actions = "";
      }
    },
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId;
    },
    userId() {
      return this.$store.getters.userId;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.newContactGroup.email.$dirty) return errors;
      !this.$v.newContactGroup.email.email && errors.push("Must be valid e-mail");
      // !this.$v.newContactGroup.email.required && errors.push("E-mail is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.newContactGroup.telephone.$dirty) return errors;
      !this.$v.newContactGroup.telephone.numeric && errors.push("Must be valid phone number");
      !this.$v.newContactGroup.telephone.minLength && errors.push("Must be valid phone number");
      !this.$v.newContactGroup.telephone.maxLength && errors.push("Must be valid phone number");
      // !this.$v.newContactGroup.telephone.required && errors.push("Telephone is required");
      return errors;
    },
    groupnameErrors() {
      const errors = [];
      if (!this.$v.newContactGroup.groupName.$dirty) return errors;
      !this.$v.newContactGroup.groupName.required && errors.push("Groupname is required");
      return errors;
    },
  },
};
</script>
